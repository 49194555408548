<template>
    <div class="layout-config-wrap niceScroll">
        <FrameHeader frame="config" />

        <div class="layout-config-container">
            <Sider width="240" class="layout-config-sider">
                <Menu ref="menu" class="ov-scroll system-menu" mode="vertical" width="auto" theme="dark" :open-names="menu.open" accordion :active-name="menu.active">
                    <VMenuItem :menus="menu.list" :click="goUrl" />
                </Menu>
            </Sider>
            <Content class="layout-config-content">
                <Breadcrumb class="p-10">
                    <Breadcrumb-item :href="item.fullPath" v-for="item in breadcrumb" v-if="item.meta.title" :key="item.fullPath">
                        <Icon type="social-buffer-outline" />
                        <span>{{item.meta.title}}</span>
                    </Breadcrumb-item>
                </Breadcrumb>
                <div class="config-content">
                    <router-view />
                    <Loading :show="isLoading" />
                </div>
            </Content>

        </div>


    </div>
</template>

<script>
import { mapState } from 'vuex'
import FrameHeader from './components/FrameHeader.vue'
import Loading from './components/Loading'
import { config } from "@/config"
export default {
    components: {
        FrameHeader,
        Loading
    },
    computed: {
        ...mapState({
            isLoading: state => state.ivew.isLoading,
            menu: state => state.ivew.menu,
            menus: state => state.user.menu.config,
            breadcrumb: state => state.ivew.breadcrumb,
        })
    },
    mounted() {
    },
    methods: {
        goUrl(item) {
            let { model, active } = this.menu
            if (this.$route.fullPath != item.fullPath) {
                this.$router.push({ path: item.fullPath })
            } else {
                this.$router.push({ path: item.path })
            }
        },
    }

}
</script>

<style lang="less">
.layout-config-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    color: #333;
    overflow: hidden;

    .layout-config-container {
        width: 100%;
        height: 100%;
        display: flex;
        padding-top: 60px;
        .layout-config-sider {
            
        }
        
    }
    .layout-config-content {
          width: 100%;
          height: 100%;
          padding: 0 10px 10px;
          .config-content {
            width: 100%;
            height: calc(100% - 40px);
            box-shadow: 0 0 5px 3px #eee;
            .layout-config {
                overflow-y: scroll;
            }
            .ivu-table {
                .ivu-btn {
                margin-right: 5px;
            }
            }
          }

    }
}
</style>